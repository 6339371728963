import React from "react"
import PageTemplate from '../components/pageTemplate'
import ProductDisplay from '../components/product-display'
import { graphql } from 'gatsby'

export default ({data}) => (
  <PageTemplate title="Spoon In Lid" img={data.fileName}>
    <div className="text-center">
      <p>The Raybek Foods unique spoon in lid system is the perfect on the go snack.</p>
      <p>This perfectly portioned 180g size is a great for work and school lunches, after gym fuel or to compliment your daily meal plan.</p>
      <p>Available in five great flavours, the Raybek Foods spoon in lid yoghurt makes your busy lifestyle that much easier.</p>
    </div>
    <ProductDisplay products={data.yoghurts}/>
  </PageTemplate>
)

export const query = graphql`
  query {
    fileName: file(relativePath: { eq: "banner-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 540) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yoghurts: allPrismicProducts(filter: {data: {product_type: {eq: "spoon-in-lid"}}}) {
      edges {
        node {
          ...ProductInfo
        }
      }
    }
  }
`
